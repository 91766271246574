import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql } from 'gatsby';
import { Box } from '@chakra-ui/react';
import Header from '../components/ui/Header';
import Section from '../components/ui/Section';
import Containers from '../components/ui/Containers';

// Template for producing all the boring pages in pages in the datoCMS other pages model

type Props = {
  data: {
    datoCmsPage: {
      seoMetaTags: {
        id: string;
        children: any[];
        parent: any;
        tags: any[];
      };
      title: string;
      subtitle: string;
      featuredMedia?: {};
      content: string;
    };
  };
};

const LoanPagesTemplate = ({ data }: Props) => {
  const { seoMetaTags, content, title, featuredMedia } = data.datoCmsPage;

  return (
    <>
      <HelmetDatoCms seo={seoMetaTags} />
      <Header title={title} image={featuredMedia && featuredMedia} />

      <Section mb={[4, 24]}>
        <Containers>
          <Box maxWidth="700px">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Box>
        </Containers>
      </Section>
    </>
  );
};

export default LoanPagesTemplate;

export const query = graphql`
  query PageQuery($slug: String!) {
    datoCmsPage(slug: { eq: $slug }) {
      title
      subtitle
      content
      featuredMedia {
        alt
        gatsbyImageData(
          width: 700
          placeholder: BLURRED
          forceBlurhash: false
          imgixParams: { fm: "jpg", auto: "compress", fit: "clamp", h: "700" }
        )
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;
